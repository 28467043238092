import { SeatsRequest } from '@api/seats'
import { SeatsLayout, SeatsList } from '@hooks/useSeatsController'
import date from '@lib/date'
import utils from '@lib/utils'
import { ParamsStore } from '@stores/params'

export type SeatsData = (Seat.Data[] | undefined)[]

export interface SeatsConnections {
  outbound: Connection | null
  inbound: Connection | null
}

const currencyIcons: Record<string, string> = {
  EUR: 'euro',
  USD: 'dollar',
  GBP: 'pound',
}

const supportLabels = ['WC', 'ES']

const getCurrencyIcon = (currency: Currency): string => currencyIcons[currency] ?? 'star-simple'

const getSeatsCount = (seats?: Record<string, Seat.Entry[]> | null): number => {
  if (!seats) return 0

  return Object.values(seats).reduce((acc, currentValue) => acc + currentValue.length, 0)
}

const getUniqueFares = (layout: Seat.Data): string[] => {
  const array = [...new Set(layout?.cars.flatMap(item => item.seats.map(el => el.fareClass)))]

  return array.filter(item => item !== null) as string[]
}

const getUniqueLevel = (layout: Seat.Data, fareClassCode: string): number[] => [
  ...new Set(
    layout?.cars.flatMap(item =>
      item.seats.filter(item => item.fareClass === fareClassCode).map(el => el.coordinates.z),
    ),
  ),
]

export interface BuildSeatsProps {
  limitations?: boolean
}

const buildSeatsParams = (
  connection: Connection | Segment,
  params: ParamsStore,
  props?: BuildSeatsProps,
): SeatsRequest => {
  const { locale, currency } = params

  return utils.object.compact<SeatsRequest>({
    marketingCarrierCode: connection.marketingCarrier.code,
    departureStation: connection.departureStation.code,
    arrivalStation: connection.arrivalStation.code,
    departureTime: date.formatDateISO(date.parse(connection.departureTime, 'UTC')),
    arrivalTime: date.formatDateISO(date.parse(connection.arrivalTime, 'UTC')),
    extraFields: props?.limitations ? 'cars.limitations,cars.seats.limitations' : null,
    locale,
    currency,
  })
}

const toUrlParams = (selected: SeatsList): Record<string, Partial<Seat.UrlParam[]>> => {
  const entries = Object.entries(selected).map(([key, value]) => [
    key,
    value.map(item => ({ price: item.price ?? 0, code: item.code })),
  ])

  return Object.fromEntries(entries)
}

const filterLayoutByFareClass = (layout: Seat.Data[], fareClassCode: string | null): any =>
  layout?.map(item => ({
    ...item,
    cars: [
      {
        ...item.cars,
        seats: item.cars[0].seats.filter(el => el.fareClass === fareClassCode || supportLabels.includes(el.label)),
      },
    ],
  }))

const sumPrice = (seats: Seat.Entry[]): number => utils.array.sum(seats, seat => Number(seat.price))

const sumOutboundPrice = (selected: SeatsList, connection: Connection | null, currency: Currency): Money =>
  Object.values(selected)
    .flatMap(item => item.map(el => ({ fareClass: el.fareClass, price: el.price })))
    .reduce(
      (acc, curr): Money => {
        const current = connection?.fares.find(el => el.fareClass.code === curr.fareClass)

        return {
          fractional: acc.fractional + (current?.price.fractional ?? /* istanbul ignore next */ 0) + Number(curr.price),
          currency,
        }
      },
      { fractional: 0, currency },
    )

const MAX_PRICE_CATEGORY = 3

const getPriceCategories = (seats: Seat.Entry[]): Record<number, number> => {
  const prices = seats.filter(seat => seat.price != null && seat.price > 0).map(seat => seat.price as number)

  return [...new Set<number>(prices)]
    .sort((a, b) => a - b)
    .reduce((mem, price, index) => ({ ...mem, [price]: Math.min(index + 1, MAX_PRICE_CATEGORY) }), {})
}

const getSeatsLimitations = (car: Seat.Car): DiscountCard.Item[] => car.seats.flatMap(seat => seat.limitations ?? [])

const getDiscountCategories = (cars: Seat.Car[]): DiscountCard.Item[] => {
  const limitations = cars.flatMap(car => [...(car.limitations ?? []), ...getSeatsLimitations(car)])

  return utils.array.uniqueBy(limitations, 'name')
}

const flatten = (seats?: SeatsList): Seat.Entry[] => Object.values(seats ?? {}).flat()

const isLimitedBy = (seat: Seat.Entry, discount: string): boolean =>
  !!utils.array.findBy(seat.limitations ?? [], 'name', discount)
const isLimitedSeatsTaken = (seats: Seat.Entry[], discountCard: string): boolean => {
  let limitedSeatFound = false

  for (const seat of seats) {
    if (isLimitedBy(seat, discountCard)) {
      if (seat.vacant) return false

      limitedSeatFound = true
    }
  }

  return limitedSeatFound
}
const getSeatsLayout = (data: SeatsData, connections: SeatsConnections): SeatsLayout =>
  data.reduce<SeatsLayout>(
    (acc, curr) => {
      /* istanbul ignore next */
      if (!curr) return acc

      const { outbound, inbound } = connections
      const { segment } = curr[0]

      const isOutbound = outbound?.segments.some(
        s =>
          s.departureStation.code === segment.departureStation.code &&
          s.arrivalStation.code === segment.arrivalStation.code,
      )

      if (isOutbound) return { ...acc, outbound: [...acc.outbound, curr[0]] }
      if (inbound) return { ...acc, inbound: [...acc.inbound, curr[0]] }

      return acc
    },
    { outbound: [], inbound: [] },
  )

const seatSelectionUtils = {
  getSeatsCount,
  getUniqueFares,
  getUniqueLevel,
  toUrlParams,
  buildSeatsParams,
  filterLayoutByFareClass,
  sumPrice,
  sumOutboundPrice,
  getPriceCategories,
  getCurrencyIcon,
  flatten,
  getDiscountCategories,
  isLimitedSeatsTaken,
  getSeatsLayout,
}

export default seatSelectionUtils
