import { Filter, Sorting } from '@enums'
import makeApi from '@lib/api'
import { Colors } from '@lib/style'
import { TicketsAction } from '@queries/settings/enum'

interface BackgroundImageSetting {
  enabled: boolean
  headerOffset: boolean
  url: string | null
}

interface BookedOutSetting {
  enabled: boolean
}

interface CheckoutSetting {
  type: 'old' | 'new' | 'express'
}

type ColorsSetting = Colors

interface CurrencySetting {
  supported: Currency[]
  default: Currency
}

interface FareClassesSetting {
  displayOn: 'nowhere' | 'checkout' | 'search_results' | 'everywhere'
  supported: string[]
  split: boolean
}

interface FilterSetting {
  enabled: boolean
  supported: Filter[]
}

interface GovernmentIdSetting {
  trips: TripCondition
  types: GovernmentIdType[]
  default: GovernmentIdType | null
  extended?: boolean
  fields: GovernmentIdFields[]
}

interface GenderSettings {
  enabled: boolean
  trips: TripCondition
}

interface LocaleSetting {
  supported: Locale[]
  default: Locale
}

interface LogoSetting {
  url: string
  redirectionUrl: string | null
}

interface PassengerTypeSetting {
  enabled: boolean
}

interface ReservationSetting {
  enabled: boolean
}

interface ReturnTripSetting {
  enabled: boolean
}

interface ReservationSetting {
  enabled: boolean
}

interface QuickReservationSetting {
  enabled: boolean
}

interface CustomDomainSetting {
  enabled: boolean
}

interface AncillarySetting {
  enabled: boolean
}

interface PassengerTypesListSetting {
  enabled: boolean
  carrier: string | null
}

interface DiscountCodesSetting {
  enabled: boolean
  displayOn: 'checkout' | 'everywhere'
}

interface AmtrackSavedCardsSetting {
  enabled: boolean
  cvc: boolean
}

interface CarrierLogoSetting {
  enabled: boolean
  source: 'operating_carrier' | 'marketing_carrier'
}

interface PaxDiscountCodesSetting {
  enabled: boolean
  displayOn: 'checkout' | 'everywhere' | 'search_results'
}

interface SearchBarSettings {
  layout: Direction
}

interface SearchResultURLSetting {
  url: string
}

interface SeatSelectionSetting {
  enabledOnSearchResults: boolean
  enabledOnCheckout: boolean
}

interface SortingSetting {
  enabled: boolean
  supported: Sorting[]
  default: Sorting | null
}

export interface SiteNameSetting {
  name: string
  alternateName?: string[]
}

export interface OrganizationSetting {
  name: string
  alternateName?: string[]
  description?: string
  email?: string
  telephone?: string
  foundingDate?: string
  legalName?: string
  logo?: string
  image?: string
  sameAs?: string[]
  url?: string
  address?: {
    streetAddress?: string
    addressLocality?: string
    addressRegion?: string
    postalCode?: string
    addressCountry?: string
  }
}

interface TitleSetting {
  text: string
  favicon: string
  siteName?: SiteNameSetting
  organization?: OrganizationSetting
}

interface TripTimeSetting {
  enabled: boolean
}

interface CPFSettings {
  enabled: boolean
}

interface PaymentProviderInteface {
  code: PaymentProvider
  options: Record<string, any>
}

interface AdyenPaymentProviderSetting extends PaymentProviderInteface {
  code: 'adyen'
  options: {}
}

interface VGSPaymentProviderSetting extends PaymentProviderInteface {
  code: 'vgs'
  options: {
    cvc: boolean
  }
}

type PaymentProviderSetting = AdyenPaymentProviderSetting | VGSPaymentProviderSetting

export interface PaymentMethodsSetting extends Record<string, boolean> {
  applePay: boolean
  googlePay: boolean
  creditCard: boolean
  pix: boolean
  paypal: boolean
  cash: boolean
  terminal: boolean
  invoice: boolean
  blik: boolean
}

interface BillingAddressSetting {
  enabled: boolean
}

interface HoldBookingSettings {
  enabled: boolean
}

interface DownloadTicketSetting {
  enabled: boolean
  action: TicketsAction
}

interface MarketingMaterialsSetting {
  enabled: boolean
}

interface VehicleIdentifierSetting {
  enabled: boolean
}

interface CalendarSettings {
  enabled: boolean
  displayPrice: 'all_days' | 'today'
}

interface EmailSetting {
  enabled: boolean
  optional: boolean
  verification: boolean
}

interface EndpointsSetting {
  newBackend: string[]
}

interface WhatsappButtonSetting {
  enabled: boolean
  phoneNumber: string | null
}

interface TrackTripSetting {
  enabled: boolean
}

interface ManageBookingsSetting {
  enabled: boolean
  backgroundUrl: string | null
}

interface CustomElementsSetting {
  enabled: boolean
  pages: PageBlocks.Structure[]
}

interface NethoneSetting {
  enabled: boolean
}

interface MenuSettings {
  items: NavBar.Item[]
}

interface PriceCalendarSetting {
  enabled: boolean
}

interface AccommodationSetting {
  enabled: boolean
  partner: Partners
}

interface SupportSettings {
  url?: string | null
}

interface FooterMenuItem {
  urls: string[]
  icon?: string
}

interface FooterSocialItem {
  type: string
  url: string
}

interface FooterSettings {
  menu: FooterMenuItem[][]
  socials: FooterSocialItem[]
}

interface AgentCounterPageSetting {
  departureLocation: Location.Item | null
  arrivalLocation: Location.Item | null
}

interface TransportationModeSetting {
  enabled: boolean
  supported: Lowercase<Vehicle.Code>[]
}

interface SuggestionsSetting {
  display: Location.Type[]
  count: number
}

interface DiscountVoucherSetting {
  enabled: boolean
}

interface MediaSetting {
  enabled: boolean
  displayOn: 'search_results' | 'checkout' | 'everywhere'
  preview: boolean
}

interface ResellingSetting {
  enabled: boolean
  image: string | null
  link: string | null
}

interface NameSetting {
  enabled: boolean
}

interface TermsAndPrivacySetting {
  enabled: boolean
}

interface SeoSettings {
  enabled: boolean
}

export interface DistribusionBrandingSetting {
  enabled: boolean
  pages: string[]
}

interface CookiesConsentSettings {
  enabled: boolean
  provider: CookiesConsentProvider
}

export interface SettingsResponse {
  discountVoucher: DiscountVoucherSetting
  backgroundImage: BackgroundImageSetting
  bookedOut: BookedOutSetting
  checkout: CheckoutSetting
  colors: ColorsSetting
  currency: CurrencySetting
  fareClasses: FareClassesSetting
  filter: FilterSetting
  governmentId: GovernmentIdSetting
  locale: LocaleSetting
  logo: LogoSetting
  passengerType: PassengerTypeSetting
  returnTrip: ReturnTripSetting
  searchBar: SearchBarSettings
  searchResultUrl: SearchResultURLSetting
  seatSelection: SeatSelectionSetting
  sorting: SortingSetting
  tripTime: TripTimeSetting
  cpf: CPFSettings
  paymentProvider: PaymentProviderSetting
  paymentMethods: PaymentMethodsSetting
  reservation: ReservationSetting
  passengerTypesList: PassengerTypesListSetting
  discountCodes: DiscountCodesSetting
  paxDiscountCodes: PaxDiscountCodesSetting
  ancillaries: AncillarySetting
  customDomain: CustomDomainSetting
  quickReservation: QuickReservationSetting
  amtrackSavedCards: AmtrackSavedCardsSetting
  billingAddress: BillingAddressSetting
  carrierLogo: CarrierLogoSetting
  holdBooking: HoldBookingSettings
  downloadTicket: DownloadTicketSetting
  marketingMaterials: MarketingMaterialsSetting
  searchCalendar: CalendarSettings
  vehicleIdentifier: VehicleIdentifierSetting
  email: EmailSetting
  title: TitleSetting
  endpoints: EndpointsSetting
  whatsappButton: WhatsappButtonSetting
  gender: GenderSettings
  trackTrip: TrackTripSetting
  customElements: CustomElementsSetting
  manageBookings: ManageBookingsSetting
  nethone: NethoneSetting
  menu: MenuSettings
  priceCalendar: PriceCalendarSetting
  accommodation: AccommodationSetting
  footer: FooterSettings
  support: SupportSettings
  agentCounterPage: AgentCounterPageSetting
  transportationMode: TransportationModeSetting
  suggestions: SuggestionsSetting
  media: MediaSetting
  reselling: ResellingSetting
  passengerName: NameSetting
  termsAndPrivacy: TermsAndPrivacySetting
  seo: SeoSettings
  distribusionBranding: DistribusionBrandingSetting
  cookiesConsent: CookiesConsentSettings
}

export interface SettingsRequest {
  marketingCarrierCode: string | null
  retailerPartnerNumber: number
}

export default {
  load: makeApi.get<SettingsRequest, SettingsResponse>('/settings'),
}
