import React, { useMemo, useState } from 'react'

import SeatsLoader from '@components/JourneyList/SeatsLoader'
import SeatSelection from '@components/SeatSelection'
import { SeatsList } from '@hooks/useSeatsController'
import { useSeatsSelection } from '@hooks/useSeatsSelection'
import seatSelectionUtils from '@lib/seatSelection'
import useSeatsQueries from '@queries/seats'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

export interface SeatSelectionData {
  seats: SeatsList
  passengerCard?: DiscountCard.Item | null
}

interface Props {
  onClose: () => void
  connection: Connection
  fareClassFilter?: string | null
  initialFareClass?: string | null
  onSubmit: (data: SeatSelectionData) => void
  outboundSeatsCount?: number
  pax: number
}

const SeatsModal = (props: Props) => {
  const { onClose, connection, fareClassFilter, initialFareClass, onSubmit, outboundSeatsCount, pax } = props
  const [params] = useParams()
  const [{ paxDiscountCodes }] = useSettings()
  const isLimitedDiscountFlow = paxDiscountCodes.enabled && paxDiscountCodes.displayOn === 'search_results'
  const seatsProps = {
    connections: { outbound: connection, inbound: null },
    options: { limitations: isLimitedDiscountFlow },
  }
  const [data, meta, refetch] = useSeatsQueries({ ...seatsProps })
  const isSeatsLoaded = meta.status.every(status => status === 'success')

  const filteredLayout = useMemo(
    () =>
      fareClassFilter && data.length
        ? seatSelectionUtils.filterLayoutByFareClass(data.flat() as Seat.Data[], fareClassFilter)
        : data.flat(),
    [fareClassFilter, data],
  )

  const { selectedSeats, toggleSeat, reset: resetSelected } = useSeatsSelection()
  const [segment, setSegment] = useState<number>(0)

  const selectedSegmentSeats = useMemo(() => selectedSeats[segment] ?? [], [segment, selectedSeats])
  const handleSubmit = (passengerCard?: DiscountCard.Item | null): void => {
    if (filteredLayout.length > segment + 1) {
      setSegment(segment + 1)
      return
    }

    onSubmit({ seats: selectedSeats, passengerCard })
  }

  return (
    <>
      {!isSeatsLoaded && (
        <SeatsLoader loading={meta.isLoading} error={meta.error?.code} refetch={refetch} onClose={onClose} />
      )}
      {isSeatsLoaded && !!data.filter(Boolean).length && (
        <SeatSelection
          opened
          layout={filteredLayout}
          selected={selectedSegmentSeats}
          reservedSeatsCount={params.pax}
          outboundSeatsCount={outboundSeatsCount}
          segmentIndex={segment}
          onSelect={seat => toggleSeat(seat, segment)}
          onSubmit={handleSubmit}
          onClose={onClose}
          connection={connection}
          selectedFareClassCode={initialFareClass}
          showHeader={data.length >= 2}
          onClick={resetSelected}
          isFullPrice
          requiredSeats={isLimitedDiscountFlow ? pax : undefined}
          discountDropdown={isLimitedDiscountFlow && pax === 1}
        />
      )}
    </>
  )
}

export default SeatsModal
